.para-pro {
  width: 500px;
}
@media (max-width: 1330px) {
  .para-pro {
    width: 450px;
  }
}
@media (max-width: 1220px) {
  .para-pro {
    width: 400px;
  }
}
@media (max-width: 992px) {
  .caro-img-pro {
    text-align: center;
    justify-content: center;
  }
  .dumy-pro {
    display: none;
  }
  .caro-content-pro {
    width: 100%;
    padding: 30px 50px;
  }
  .btn-know {
    justify-content: center;
  }
  .vid-caro {
    margin: auto;
  }
  .para-pro {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .caro-img-pro {
    height: auto;
  }
}

.slick-dots {
  bottom: auto !important;
  text-align: start !important;
  display: flex !important;
  gap: 130px;
  left: -5px;
  bottom: -25px !important;
}

.gal-pro-img {
  width: 100%;
  height: 550px;
}
.gal-pro-small {
  height: 100px;
  width: 150px;
}
@media (max-width: 992px) {
  .gal-pro-img {
    height: 400px;
  }
  .gal-pro-small {
    height: 50px;
    width: 100px;
  }

  .slick-dots {
    gap: 78px;
  }
  .gal-pro-div {
    overflow-x: scroll;
  }
}

.charges-table {
  width: 100%;
}
.charges-table th {
  padding: 10px 0 10px 18px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 300;
  font-size: 0.75rem;
}
.charges-table tr td {
  padding: 10px 0 10px 18px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 300;
  font-size: 0.75rem;
}
.charges-table th {
  background-color: black;
  color: white;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
}
.charges-table tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.2);
}
.charges-table tr:nth-child(even) {
  background-color: black;
}
.charges-table tr:hover {
  cursor: pointer;
}

.service-each-card{
    padding: 30px 25px;
    background-color: rgba(217, 217, 217, 0.2);
    cursor: pointer;
    border-right: 10px solid black;
    border-bottom: 10px solid black;
}
.service-each-card:hover{
    background-color: rgba(217, 217, 217, 0.4);
}

.form-email{
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 10px 150px; 
    border-radius: 7px;
    outline: none;
    margin: auto;
    text-align: center;
    color: white;
}

.tgwp{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    gap: 25px;
    background-color: transparent;
    width: 100%;
    padding: 5px 0;
    width: 100%;
    z-index: 99;
}
.tgwp-1{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    gap: 15px;
    background-color: transparent;
    width: 100%;
    padding: 10px 0;
    width: 100%;
    z-index: 99;
    position: fixed;
    bottom: 0;
    display: none;
}
@media(max-width:768px){
  .tgwp-1{
    display: flex;
    z-index: 100;
  }
}
.btn-link-wptg{
  background-color: white;
  color: black;
  padding: 5px 20px;
  border-radius: 8px;
  cursor: pointer;
}
@media(max-width:992px){
  .stat-line{
    margin-top: 40px;
  }
}
@media(max-width:600px){
    .form-email{
        width: 100%;
        padding: 10px 30px; 
    }
}
